.headContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: 2%;
}
.searchbarContainer{
    width: 30%;
}
.searchInput{
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}
.searchbar{
    flex: 1 1;
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-family: 'Nunito';
    border-radius: 30px;
    padding: 3%;
    width: 100%;
}
.err{
    padding-top: 1%;
    padding-left: 1%;
    color: red;
    font-family: Arial, Helvetica, sans-serif;
}
.err span{
    padding-right: 2%;
}
.submitBtn{
    position: absolute;
    right: 3%;
    border: none;
    border-radius: 50%;
    background-color: #029BC5;
    color: white;
    font-family: 'Nunito';
    padding: 0.5%;
    cursor: pointer;
}
.submitBtn:hover{
    background-color: #029bc5cf;
}
.submitBtn:disabled{
    background-color: #999;
    cursor: not-allowed;
}