.countriesContainer{
    margin: 2%;
    box-shadow: 1px 1px 10px #ccc;
    padding: 1%;
}
.countriesContainer ul{
    display: flex;
    justify-content: flex-start;
    list-style-type: none;
    flex-wrap: wrap;
    padding: 0;
    margin: 3%;
}
.card{
    text-decoration: none;
    color: #171717;
    flex: 0 0 18%;
    box-shadow: 1px 1px 3px #ccc;
    margin: 1%;
    padding: 2%;
}
.card:hover {
    box-shadow: 1px 1px 5px #555;
}
.card li{
    display: flex;
    flex-direction: column;
    text-align: center;
}
.like{
    position: absolute;
    right: -5%;
    top: -5%;
    border: 2px solid #555;
    border-radius: 50%;
    padding: 2%;
    cursor: pointer;
}
.like:hover{
    background-color: #555;
}
.imgContainer {
    position: relative;
    width: 100%;
    height: 200px;
}
.imgContainer img{
    width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: cover;
}
.caption{
    text-align: start;
    padding: 1%;
    padding-top: 10%;
    font-family: 'Nunito Sans';
}
.caption span{
    margin-right: 3%;
}