.heroContainer{
    position: relative;
    width: 100%;
    height: 500px;
}
.heroContainer > img{
    object-fit: cover;
    width: 100%;
    max-height: 100%;
}
.heroTxt{
    font-size: 200%;
    color: white !important;
    font-weight: bold;
}
.countryDescription{
    max-width: 50%;
    position: absolute;
    left: 0;
    bottom: 0;
    font-family: 'Nunito', sans-serif;
    padding: 2%;
    background-color: rgb(0, 0, 0, 0.5);
    color: white;
}
.countryDescription h1{
    color: white;
}
.countriesContainer{
    margin: 2%;
    box-shadow: 1px 1px 10px #ccc;
    padding: 1%;
}
.countriesContainer ul{
    display: flex;
    justify-content: flex-start;
    list-style-type: none;
    flex-wrap: wrap;
    padding: 0;
    margin: 3%;
}
.card{
    text-decoration: none;
    color: #171717;
    flex: 0 0 18%;
    box-shadow: 1px 1px 3px #ccc;
    margin: 1%;
    padding: 2%;
}
.card:hover {
    box-shadow: 1px 1px 5px #555;
}
.card li{
    display: flex;
    flex-direction: column;
    text-align: center;
}
.imgContainer {
    position: relative;
    width: 100%;
    height: 200px;
}
.imgContainer img{
    width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: cover;
}
.caption{
    text-align: start;
    padding: 1%;
    padding-top: 10%;
    font-family: 'Nunito Sans';
}
.caption .name{
    font-size: large;
    font-weight: bold;
}
.caption .title{
    font-size: small;
}
.placeDescription{
    border-top: 1px solid #555;
    padding-top: 3%;
    font-family: 'Nunito', sans-serif;
}
.placeLocation span{
    margin-right: 1%;
}
.placeLocation{
    padding-top: 3%;
    font-family: 'Nunito', sans-serif;
    color: #029BC5;
}
.modal{
    display: flex;
    flex-direction: column;
    padding: 5%;
    width: 100%;
}